import React from "react"
import { Box, styled, Typography } from "@mui/material"

const StyledBox = styled(Box)((props, theme) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}))

const StyledButton = styled("button")(({ theme }) => ({
  width: "66%",
  border: "none",
  borderRadius: theme.spacing(3),
  padding: theme.spacing(0.5),
  cursor: "pointer",
}))

const CallToAction = ({
  buttonText,
  buttonColor,
  onButtonClick,
  descText,
  descColor,
  ...props
}) => (
  <StyledBox pt={4} pb={8} {...props}>
    {descText && (
      <Typography
        color={descColor}
        fontSize="28px"
        fontWeight="bold"
        textTransform="uppercase"
        my={2}
        letterSpacing="2px"
        lineHeight="36px"
      >
        {descText}
      </Typography>
    )}
    <StyledButton sx={{ backgroundColor: buttonColor }} onClick={onButtonClick}>
      <Typography
        color="white"
        fontSize="22px"
        fontWeight="bold"
        textTransform="uppercase"
      >
        {buttonText}
      </Typography>
    </StyledButton>
  </StyledBox>
)

export default CallToAction
