import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionOne from "../components/sectionOne"
import { getImage, StaticImage } from "gatsby-plugin-image"
import CallToAction from "../components/common/callToAction"
import BaseContainer from "../components/common/BaseContainer"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"
import { Box, styled } from "@mui/material"
import Typography from "@mui/material/Typography"
import BackgroundImage from "gatsby-background-image"
import useMediaQuery from '@mui/material/useMediaQuery';

const SectionContainer = ({ children, sx = {}, ...props }) => (
  <Box
    display="grid"
    {...props}
    sx={{
      gridTemplateColumns: {
        xs: "1fr",
        md: "repeat(2, 1fr)",
      },
      ...sx,
    }}
  >
    {children}
  </Box>
)

const HeroText = styled(Typography)({
  fontSize: { xs: "66px", md: "96px", lg: "130px" },
  lineHeight: { xs: "62px", md: "90px", lg: "112px" },
  filter: "drop-shadow(0 0 25.5px black)",
})

const HeroWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  color: "white",
  "&::before": {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: -10,
  },
}))

const Hero = () => {
  const { placeholderImage } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "oferta_hero_4.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `)
  const image = getImage(placeholderImage)

  const bgImage = convertToBgImage(image)

  const matches = useMediaQuery("(max-width:1100px)")

  return (
    <HeroWrapper color="white">
      <BackgroundImage
        Tag="section"
        {...bgImage}
        preserveStackingContext
        backgroundColor='#180045'
        style={{
          backgroundPosition: `top ${matches ? '-100px' : ''} center`,
          backgroundSize: "auto 100%",
        }}
      >
        <BaseContainer sx={{ py: 6, pb: 6 }}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography
              variant="h1"
              color="primary.light"
              sx={{
                fontSize: { xs: "96px", lg: "130px" },
                lineHeight: { xs: "90px", lg: "112px" },
              }}
            >
              WYJĄTKOWA
              <br />
              OFERTA
              <br />
              MUSIC POINT
            </Typography>
            <Box
              sx={{
                filter: "drop-shadow(0 0 25.5px #180045)",
              }}
            >
              <Typography my={2} mt={8} fontSize={26} lineHeight={"32px"}>
                MUSIC POINT jest jedyną w swoim rodzaju, wyjątkową ofertą na
                rynku muzyki zwolnionej
                <br />z opłat za publiczne odtwarzanie w Polsce.
              </Typography>
              <Typography fontSize={26} lineHeight={"32px"}>
                MUSIC POINT to najwyższa muzyczna jakość, to zbiór wyjątkowych,
                starannie dobranych,
                <br />
                ekskluzywnych nagrań{" "}
                <span style={{ textDecoration: "underline" }}>
                  (nie jest to produkowana na masową skalę muzyka royalty free).
                </span>
                <br />
                Oferta MUSIC POINT sprawi, że Państwa placówka /obiekt stanie
                się miejscem wyjątkowym.
              </Typography>
            </Box>
          </Box>
        </BaseContainer>
      </BackgroundImage>
    </HeroWrapper>
  )
}

const SectionHeading = styled(Typography)(({ theme }) => ({
  fontSize: "67px",
  lineHeight: "73px",
  paddingBottom: theme.spacing(2),
}))

const OfferPage = () => {
  return (
    <Layout>
      <Seo title="Oferta audiomarketingu Musicpoint" />
      <Hero />

      <SectionOne />
      <SectionContainer sx={{ px: { xs: 2, md: 0 } }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <Box
            sx={{
              color: "black",
              maxWidth: theme => theme.breakpoints.values.sm + "px",
              py: 4,
              px: { md: 4 },
            }}
          >
            <SectionHeading color={"secondary.main"} variant="h2" py={4}>
              KOMPLEKSOWA OBSŁUGA W&nbsp;ZAKRESIE AUDIOMARKETINGU
            </SectionHeading>
            <Typography>
              <strong>
                Dużą rolę przykładamy do nowoczesnej komunikacji dźwiękowej.
                Tworzymy od podstaw brzmienia, które identyfikują markę i tworzą
                jej tożsamość.{" "}
              </strong>
              Strategia wdrażania identyfikacji dźwiękowej obejmuje m.in. jingle
              radiowe, które standardowo znajdują się na playlistach.
            </Typography>
            <Typography mt={4}>
              <strong>
                Elementem, który otwiera nowe ścieżki komunikacji z klientem są
                tworzone i produkowane specjalne utwory identyfikujące sieć.
              </strong>{" "}
              Taką dedykowaną indywidualną muzykę brandu można implementować nie
              tylko w placówkach handlowych, ale także np. w biurach, na
              firmowych wyjazdach integracyjnych, podczas gal okolicznościowych.
              Nasze produkcje wykorzystywane są też w ogólnopolskich kampaniach
              reklamowych np. w <strong>Radio RMF FM</strong>,{" "}
              <strong>Radio ZET</strong>, jak również po synchronizacji obrazu i
              muzyki - emitowane w telewizjach czy zamieszczane w Internecie.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <StaticImage
            src="../images/ludzie_polaczenia.jpg"
            alt="ludzie"
            imgStyle={{ minHeight: "100%", objectPosition: "10% center" }}
          />
        </Box>
      </SectionContainer>
      <SectionContainer
        sx={{
          backgroundColor: "secondary.main",
          color: "white",
          position: "relative",
          px: { xs: 2, md: 0 },
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        >
          <StaticImage
            src="../images/zloty_mikrofon_2.png"
            alt="zolty mikrofon"
            style={{
              width: "100%",
            }}
            objectFit="contain"
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <StaticImage
            src="../images/studio.png"
            alt="Studio"
            style={{ width: "100%", maxHeight: "750px" }}
            objectFit="cover"
            objectPosition="0 0"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "center", md: "flex-start" },
            flexDirection: "column",
            gridColumn: { xs: "1", md: "2" },
            gridRow: { xs: "1", md: "1 / 3" },
            pt: { xs: 4, md: 7 },
          }}
        >
          <Box
            sx={{
              px: { xs: 0, md: 4 },
              pb: { xs: 0, md: 18 },
              zIndex: 100,
              maxWidth: theme => theme.breakpoints.values.sm + "px",
            }}
          >
            <SectionHeading color={"primary.light"} variant="h2">
              SERWIS MUZYCZNY
              <br />
              MUSIC POINT
            </SectionHeading>
            <Typography py={1}>
              Nasze playlisty od ponad 10 lat rozbrzmiewają w placówkach w całej
              Polsce, w szczytowych momentach osiągając ilość nieomal 10
              tysięcy. Nasza usługa Audiomarketingu aktualnie dociera do kilku
              tysięcy placówek.
            </Typography>
            <Typography py={1}>
              Serwis Muzyczny Music Point tworzymy indywidualnie dla każdej z
              firm w oparciu o zasady Audiomarketingu, zgodnie z oczekiwaniami
              Klienta oraz określonej grupy docelowej. Powstaje w ten sposób
              budowane od podstaw, dedykowane, oryginalne profesjonalne Firmowe
              Radio Sieciowe dla Ciebie i Twoich Klientów.
            </Typography>
            <Typography py={1}>
              <strong>
                Usługa Firmowego Radia Sieciowego świadczona jest drogą
                elektroniczną przez Internet. Nie jest niezbędna ingerencja
                personelu np. istnieje możliwość zdalnego uruchamiania i
                wyłączania o ustalonej godzinie.
              </strong>
            </Typography>
            <Typography py={1}>
              Oprócz dedykowanych jingli i specjalnych piosenek identyfikujących
              markę, playlisty standardowo zawierają również wiele innych
              atrakcyjnych elementów jak np. cykle krótkich zawsze dobrze
              odbieranych przez Konsumentów audycji tematycznych, codziennie
              zmieniające się Kalendarium Dzień Po Dniu zawierające m.in.
              życzenia imieninowe dla solenizantów w danym dniu, czy też
              horoskopy urodzinowe. Szereg dodatkowych elementów jest
              udostępnianych na playlistach zgodnie z preferencjami Klienta.
            </Typography>
            <Typography py={1}>
              Ekskluzywna muzyka z katalogu Music Point jest dobierana z
              wyjątkową dbałością o najwyższy poziom artystyczny, na playlistach
              udostępniane są nagrania w zróżnicowanych gatunkach muzycznych,
              m.in. Pop, Easy listening, Latynoski pop, Dance – pop, Country,
              Funk, Soul, R&B, Blues, Reggae, Rock, Pop – rock, House, Hip –
              hop, Dance, Dancehall, Elektro Dance, Eurodance, Chillout,
              Ambient.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            gridRow: 2,
          }}
        >
          <Box
            sx={{
              py: { xs: 4, md: 0, lg: 16 },
              px: { xs: 0, md: 4 },
              maxWidth: theme => theme.breakpoints.values.sm + "px",
              zIndex: 100,
            }}
          >
            <Typography py={1}>
              Program muzyczny obejmuje głównie piosenki śpiewane po angielsku,
              francusku, portugalsku, hiszpańsku, niemiecku i po polsku oraz
              muzykę instrumentalną.
            </Typography>
            <Typography py={1}>
              Zapewniamy aktualizację bazy muzycznej, przygotowanie i
              dostosowanie zawartości muzycznej do grupy docelowej czy
              okoliczności takich jak Święta.
            </Typography>
            <Typography py={1}>
              Na playlistach pojawiają się też specjalne utwory jak np. piosenki
              o charakterze sportowym w okresie wielkich wydarzeń sportowych
              takich jak m.in. Mistrzostwa Świata w Piłce Nożnej.
            </Typography>
            <Typography py={1}>
              Platforma playlist jest zarządzana przez pracowników Music Point.
            </Typography>
            <Typography py={1} sx={{ zIndex: 10 }}>
              Muzyka z katalogu Music Point jest zwolniona z opłat na rzecz
              Organizacji Zbiorowego Zarządzania (takich jak STOART, ZPAV, SAWP
              i ZAiKS) za publiczne odtwarzanie. Zachęcamy do kontaktu z nami
              aby przekonać się jakie można poczynić oszczędności korzystając z
              dostępu do naszej muzyki oraz z profesjonalnej kompleksowej
              obsługi w zakresie Audiomarketingu.
            </Typography>
          </Box>
        </Box>
      </SectionContainer>
      <SectionContainer sx={{ backgroundColor: "primary.light", py: 8, px: {xs: 2, md: 0} }}>
        <Box
          sx={{
            color: "white",
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <Box sx={{ maxWidth: theme => theme.breakpoints.values.sm + "px" }}>
            <SectionHeading variant="h2" color="secondary.main">
              REKLAMA W MIEJSCU SPRZEDAŻY
            </SectionHeading>
            <p>
              <strong>
                Nasz Serwis Muzyczny jest niezwykle istotnym kanałem
                <br />
                komunikacyjnym w oddziaływaniu na Konsumenta bezpośrednio w
                miejscu sprzedaży.
              </strong>
            </p>
            <p>
              W ramach świadczonych usług oferujemy przygotowanie, produkcję i
              emisję dźwiękowych kampanii reklamowych.
              <br />
              Music Point za pomocą profesjonalnych, specjalnie przygotowywanych
              spotów reklamowych we właściwy sposób dociera z przekazem do
              Konsumenta, umiejętnie wpływając na jego preferencje i decyzje
              zakupowe.
            </p>
            <p>
              Profesjonalne nagrania wszystkich promocyjnych
              <br />
              materiałów audio realizujemy na najwyższym poziomie w naszych
              studiach nagrań z udziałem lektorek i lektorów, których głosy
              znane są z dużych kampanii ogólnopolskich.
            </p>
          </Box>
        </Box>

        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <StaticImage
            src={"../images/sklep.png"}
            alt={"sklep"}
            objectFit="cover"
            imgStyle={{ height: "100%" }}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex", justifyContent: "flex-end" },
          }}
        >
          <StaticImage src={"../images/white_waves.png"} alt={"fala audio"} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          <Box
            sx={{
              maxWidth: theme => theme.breakpoints.values.sm + "px",
              pt: { xs: 0, md: 8 },
              color: "white",
            }}
          >
            <p>
              Dysponujemy dużą bazą gotowych zróżnicowanych, profesjonalnych
              podkładów muzycznych Premium co daje możliwość sprawnego
              przygotowania najwyższej jakości oprawy muzycznej do reklam.
            </p>
            <p>
              <strong>
                Oferujemy też kompleksową realizację marketingowych projektów
                specjalnych. Duża kreatywność i doświadczenie oraz ścisła
                współpraca z uznanymi, wielokrotnie nagradzanymi twórcami i
                producentami muzycznymi daje gwarancje ciekawych pomysłów i
                koncepcji przynoszących zamierzony efekt, jak również
                perfekcyjnej, profesjonalnej, na najwyższym poziomie, samej
                realizacji projektów.
              </strong>
            </p>
          </Box>
        </Box>
      </SectionContainer>
      <SectionContainer px={{ xs: 2, md: 0 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <Box
            sx={{
              maxWidth: theme => theme.breakpoints.values.sm + "px",
              px: { xs: 0, md: 2 },
              pt: 4,
            }}
          >
            <SectionHeading variant="h2" color={"secondary.main"}>
              PLAYLISTY
            </SectionHeading>

            <p>
              Rozwiązania techniczne do świadczenia kompleksowej usługi
              Audiomarketingu w sieciach handlowych ustalane są indywidualnie z
              każdą z sieci. Poszczególne placówki otrzymują indywidualne dane
              dostępowe do dedykowanego Serwisu Muzycznego Firmowego Radia.
            </p>
            <p>
              <strong>
                Playlisty tworzone są zgodnie z indywidualnymi potrzebami
                Klienta sieciowego.
              </strong>
            </p>
            <p>
              Istnieje możliwość integracji z istniejącymi systemami.
              Umożliwiamy programistyczny dostęp do naszych playlist i nagrań do
              celów integracji z systemami audiowizualnymi np. synchronizacja z
              emisją na ekranach lub z istniejącym oprogramowaniem do
              odtwarzania muzyki w placówkach.
            </p>
          </Box>
        </Box>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <StaticImage
            src="../images/neon_glosnik.jpg"
            alt="glosnik"
            objectFit="cover"
            imgStyle={{
              minHeight: "100%",
            }}
          />
        </Box>
      </SectionContainer>
      <SectionContainer>
        <Box sx={{ display: { xs: "none", md: "flex" }, maxHeight: "540px" }}>
          <StaticImage
            src="../images/neon_nutki_niebieskie.jpg"
            alt="glosnik"
            objectFit="cover"
            imgStyle={{
              maxHeight: "540px",
            }}
          />
        </Box>
        <Box
          sx={{
            px: 2,
            pt: { xs: 0, md: 4 },
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Box sx={{ maxWidth: theme => theme.breakpoints.values.sm + "px" }}>
            <p>
              Można zastosować regionalizację i równolegle udostępniać program
              Radia z różnymi zestawami spotów reklamowych do poszczególnych
              regionów sieci, bądź docierać do wydzielonej czasowo grupy lub
              pojedynczych placówek.
            </p>
            <p>
              {" "}
              Ilość kanałów w Firmowym Radio może być różnie konfigurowana w
              zależności od potrzeb Klienta. Oprócz podstawowych kanałów z
              muzyką zróżnicowaną i bardziej dynamiczną możemy też udostępniać
              np. kanały z reklamą piwa, kanały bez reklam, czy specjalne kanały
              zawierające różne okresowe akcje promocyjne, które będą dotyczyły
              jedynie wybranej grupy placówek.
            </p>
            <p>
              W ramach współpracy zapewniamy też wsparcie, pomoc techniczną dla
              poszczególnych placówek.
            </p>
          </Box>
        </Box>
      </SectionContainer>
      <BaseContainer sx={{px: 2}}>
        <CallToAction
          descText={
            <>
              W CELU UZYSKANIA DODATKOWYCH INFORMACJI
              <br />
              SKONTAKTUJ SIĘ Z NAMI I DOWIEDZ SIĘ WIĘCEJ
            </>
          }
          descColor="primary.main"
          buttonText={"Przejdź do kontaktu"}
          buttonColor={"secondary.main"}
          onButtonClick={() => navigate("/kontakt")}
        />
      </BaseContainer>
    </Layout>
  )
}

export default OfferPage
