import React from "react"
import { Box } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"

const SectionOne = () => (
  <Box
    display="grid"
    sx={{
      gridTemplateColumns: { xs: "1fr", md: "repeat(2, 1fr)" },
      backgroundColor: "primary.light",
      justifyItems: "center",
      px: {xs: 2, sm: 0},
    }}
  >
    <Box sx={{ display: { xs: "none", md: "flex" } }}>
      <StaticImage
        src="../images/centrum_handlowe.jpg"
        alt="centrum handlowe"
        objectFit="cover"
        imgStyle={{ minHeight: "100%" }}
      />
    </Box>
    <Box
      sx={{
        color: "white",
        px: { md: 4 },
        py: 4,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: { xs: "center", md: "flex-start" },
        maxWidth: theme => theme.breakpoints.values.sm + "px",
      }}
    >
      <Box>
        <p>
          Odpowiednio dobrana muzyka wywiera silny wpływ na zachowanie i
          samopoczucie Klienta w miejscach handlowych i usługowych, tworzy
          odpowiednią atmosferę, działa relaksująco, umila czas, buduje
          przywiązanie do marki, wpływa na wzrost sprzedaży, motywuje
          pracowników, podnosi atrakcyjność oferowanych usług i produktów.
        </p>
        <p>
          Ponad 72% konsumentów zdecydowanie preferuje te lokale, gdzie w tle
          gra dobra muzyka. Dobra atmosfera w placówce spowoduje, że klient do
          niej wróci.
        </p>
      </Box>
    </Box>
  </Box>
)

export default SectionOne
